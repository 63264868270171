<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section padd-30 pt-0">
              <div class="back-btn mt-0 mr-2">
                <router-link :to="{ name: 'shippingoptions'}"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
              </div> <div class="w-100 t-wrap">
                <h4 class="menu_header">Shipping Options</h4>
              </div>
            </div>
            <hr>
          </div>
          
         
          <form @submit.prevent="updateShippingOptions">
              
            <div class="setting_content w-50 padd-30">

              <!--  -->
                    
              <div class="form-group setting_form_grp">
                <div class="row">
                  <label class="control-label col-sm-5" for="lv_name_url">Name:</label>
                  <div class="col-sm-7">
                    <select class="form-control" v-model="name" >
                      <option value="">Select Shipping Method</option>
                      <option v-for="(option,index) in shippingOptions" :key="index" :value="option">{{option == 'flat' ? 'Internal delivery' : option.charAt(0).toUpperCase() + option.slice(1) }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group setting_form_grp">
                <div class="row">
                  <label class="control-label col-sm-5" for="lv_name_url">Base Price:</label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="price" placeholder="Enter Base Price">
                  </div>
                </div>
              </div>
              <div class="form-group setting_form_grp">
                <div class="row">
                  <label class="control-label col-sm-5" for="lv_name_url">Unique ID:</label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="uniqueId" placeholder="Enter Base Price">
                  </div>
                </div>
              </div>
              

            


              <div class="form-group setting_form_grp">
                <div class="row">
                  <label class="control-label col-sm-5" for="dm_api_url">Live Api Url:</label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="liveApiUrl" placeholder="Enter Live Api Url">
                  </div>
                </div>
              </div>
              <div>
                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="lv_api_username">Live Api Username:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="liveApiUname" placeholder="Enter Live Api Username">
                    </div>
                  </div>
                </div>

                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="lv_api_password">Live Api Password:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="liveApiPassword" placeholder="Enter Live Api Password">
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->

              <!--  -->
              <div>
                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="live_api_key"> Live Api Key:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="liveApiKey" placeholder="Enter Api Key">
                    </div>
                  </div>
                </div>
                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="dm_api_secret"> Live Api Secret:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="liveApiSecret" placeholder="Enter Live Api Secret">
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <hr>
            <div class="setting_content w-50 padd-30 pt-5">
              <div class="setting_header mb-4">
                <h2>Delivery POS Codes</h2>
              </div>
              <div class="form-group setting_form_grp">
                <div class="row"  v-for="posconfig in posConfigs" :key="posconfig.id">
                  <label class="control-label col-sm-5" for="dm_api_url">
                    <strong>{{ posconfig.name }}</strong>:</label>
                  <div class="col-sm-7">
                    <div class="form-group">
                      <input class="form-control" v-model="DeliveryPoscodes[posconfig.id]" :placeholder="`Enter ${posconfig.name} POS ID`">
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <hr>
            <div class="setting_content w-50 padd-30 pt-5">
              <div class="form-group setting_form_grp">
                <div class="row">
                  <label class="control-label col-sm-5" for="dm_api_url">Demo Api Url:</label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" v-model="demoApiUrl" placeholder="Enter Demo Api Url">
                  </div>
                </div>
              </div>
              <!--  -->
              <div>
                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="dm_api_username">Demo Api Username:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="demoApiUname" placeholder="Enter Demo Api Username" >
                    </div>
                  </div>
                </div>
                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="dm_api_password">Demo Api Password:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="demoApiPassword" placeholder="Enter Demo Api Password" name="email">
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div>
                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="dm_consumer_secret"> Demo Api Key:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="demoApiKey" placeholder="Enter Demo Api Key">
                    </div>
                  </div>
                </div>
                <div class="form-group setting_form_grp">
                  <div class="row">
                    <label class="control-label col-sm-5" for="dm_consumer_secret"> Demo Api Secret:</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" v-model="demoApiSecret" placeholder="Enter Demo Api Secret">
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
            <div class="row ">
              <div class="col-md-12">
                <div class="form-group ml-2r">
                  <button type="submit" class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Update</span></button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
    data() {
        return {
            demoApiPassword:'',
            demoApiUname:'',
            demoApiSecret:'',
            demoApiKey:'',
            liveApiKey:'',
            liveApiUname:'',
            demoApiUrl:'',
            liveApiSecret:'',
            liveApiPassword:'',
            liveApiUrl:'',
            name:'',
            posID:'',
            spinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Updating...",
            errors: [],
            price: '',
            optionId: this.$route.params.id,
            shippingOptions: ['internal_delivery','glovo','godel','pinktie','sendy','sendyv1','smai','swyft'],
            DeliveryPoscodes:[],
            posConfigs:[]
        }
    },
    beforeMount: async function () {
        this.getShippingOptionByid();
        this.getPosConfigs();
    },
    methods: {
      async getPosConfigs() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchant-pos-config/${this.merchantID}`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.posConfigs = response.data.data
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
            //
        },
        async updateShippingOptions(){
            this.spinner = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/update-shipping-option/"+this.optionId;

            const deliveryPosIds = [];
            for(let i=0; i < this.posConfigs.length; i++){
              deliveryPosIds.push({
                    'pos_id': this.DeliveryPoscodes[this.posConfigs[i].id],
                    'pos_systems': this.posConfigs[i].id
              })
            }

            const payload = {
                'merchant_id': parseInt(this.merchantID),
                'live_shipping_api_url':this.liveApiUrl,
                'demo_shipping_api_url': this.demoApiUrl,
                'live_shipping_api_key':this.liveApiKey,
                'demo_shipping_api_key':this.demoApiKey,
                'live_shipping_api_secret': this.liveApiSecret,
                'demo_shipping_api_secret': this.demoApiSecret,
                'live_shipping_api_username': this.liveApiUname,
                'live_shipping_api_password':this.liveApiPassword,
                'demo_shipping_api_username': this.demoApiUname,
                'demo_shipping_api_password':this.demoApiPassword,
                'shipping_api': 1,
                'pos_id': this.posID,
                'base_price': this.price,
                'shipping_type':this.name,
                'unique_id': this.uniqueId,
                'poscode': deliveryPosIds
            };

            this.axios.put(url,payload,config).then((response)=>{
                if(response.status ===200){
                    this.spinner = false;
                    toast.success('Shipping option created successfully');
                    this.$router.push({
                      name : 'shippingoptions'
                    });
                }
            }).catch((error)=>{
                this.spinner =false;
                if(error.response.status === 400){
                    this.errors = error.response.data.data
                }else if(error.response.status === 401){
                  toast.error('session has expired');
                  this.$router.push({
                          name: 'login'
                      });
                }
            });
        },
        async getShippingOptionByid(){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = this.dashBaseUrl + "/shipping-option/"+ this.optionId;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);

            this.axios.get(url,config).then((response) => {
                if(response.status === 200){
                  this.liveApiUrl = response.data.data.live_shipping_api_url;
                  this.demoApiUrl = response.data.data.demo_shipping_api_url;
                  this.liveApiKey = response.data.data.live_shipping_api_key;
                  this.demoApiKey = response.data.data.demo_shipping_api_key;
                  this.liveApiSecret = response.data.data.live_shipping_api_secret;
                  this.demoApiSecret = response.data.data.demo_shipping_api_secret;
                  this.liveApiUname = response.data.data.live_shipping_api_username;
                  this.liveApiPassword = response.data.data.live_shipping_api_password;
                  this.demoApiUname = response.data.data.demo_shipping_api_username;
                  this.demoApiPassword = response.data.data.demo_shipping_api_password;
                  this.name = response.data.data.shipping_type; 
                  this.price = response.data.data.base_price;
                  this.posID = response.data.data.pos_id;
                  this.uniqueId = response.data.data.unique_id
                  const poscodes = response.data.data.poscode;
                  for(let i=0; i < poscodes.length; i++){
                    this.DeliveryPoscodes[poscodes[i].pos_system_id] = poscodes[i].poscode;
                  }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })

        }
    },
    mixins: [ReusableDataMixins]
}
</script>
